// Hooks that are higher-order wrappers around the Fetch API for mutating specific API resources

import { useCallback } from "react";
import useSWR from "swr";
import { fetcherPatch } from "./fetcher";

// Idea is that we want to have read access to data via SWR as well as a simple
// API for patching (updating), creating, or deleting model instances.

// Define available endpoints
export type TResourceName =
  | "users"
  | "user_metadata"
  | "simple_experiment_dumps"
  | "simple_experiment_dump_detail"
  | "groups"
  | "group_metadatas"
  | "institutions"
  | "unregistered_authors"
  | "publications"
  | "publications_choicelist"
  | "projects"
  | "projects_choicelist"
  | "experiments"
  | "experiments_choicelist"
  | "materials"
  | "datafiles"
  | "procedures"
  | "procedures_simple"
  | "proceduresteps"
  | "proceduresteptypes"
  | "proceduregraphedges"
  | "analyses"
  | "characterization_results"
  | "characterization_types"
  | "scatter_plots"
  | "scatter_plot_data_series";

// Also associate each resource name with a certain Typescript type defined in
// models.d.ts.
export const resourceToTSType = {
  users: "IUser",
  user_metadata: "IUserMetadata",
  simple_experiment_dumps: "",
  simple_experiment_dump_detail: "",
  groups: "IGroup",
  group_metadatas: "IGroupMetadata",
  institutions: "IInstitution",
  unregistered_authors: "IUnregisteredAuthor",
  publications: "IPublication",
  publications_choicelist: "IPublicationChoicelistChoice",
  projects: "IProject",
  projects_choicelist: "IProjectChoicelistChoice",
  experiments: "IExperiment",
  experiments_choicelist: "IExperimentChoicelistChoice",
  materials: "IMaterial",
  datafiles: "IDataFile",
  procedures: "IProcedure",
  procedures_simple: "IProcedureSimple",
  proceduresteps: "IProcedureStep",
  proceduresteptypes: "IProcedureStepType",
  proceduregraphedges: "IProcedureGraphEdge",
  analyses: "IAnalysis",
  characterization_results: "ICharacterizationResult",
  characterization_types: "ICharacterizationType",
  scatter_plots: "IScatterPlot",
  scatter_plot_data_series: "IScatterPlotDataSeries",
};

interface IGetUrl {
  endpointUrl: TResourceName;
  pk?: number;
  actionStr?: string;
  queryParams?: Record<string, string>;
}
export const getUrl: ({ endpointUrl, pk, actionStr }: IGetUrl) => string = ({
  endpointUrl,
  pk = undefined,
  actionStr = undefined,
  queryParams = undefined,
}) => {
  var resultStr = `${import.meta.env.VITE_API_URL}/api/v1/${endpointUrl}/`;
  if (pk) {
    resultStr = resultStr.concat(pk.toString(), "/");
  }
  if (actionStr) {
    resultStr = resultStr.concat(actionStr.toString(), "/");
  }

  // Don't add trailing "?" and params unless they are defined and non-empty.
  if (queryParams && Object.keys(queryParams).length !== 0) {
    resultStr = resultStr + "?" + new URLSearchParams(queryParams);
  }
  return resultStr;
};

// Generic types for the fetcherPatch wrapper handler
interface IHandleCreateModelInstance<T> extends IGetUrl {
  id?: never;
  operation: "create";
  body: Partial<Nullable<T>>;
}
interface IHandleUpdateModelInstance<T> extends IGetUrl {
  id: number;
  operation: "update";
  body: Partial<T>;
}
interface IHandleDestroyModelInstance<T> extends IGetUrl {
  id: number;
  operation: "destroy";
  body?: never;
}

type THandleSubmitChangeModelInstance<T> =
  | IHandleCreateModelInstance<T>
  | IHandleUpdateModelInstance<T>
  | IHandleDestroyModelInstance<T>;

export type THandleSubmitChangeModelInstanceResult<T> =
  // Success state: API returns created/updated object of type T
  | T
  // ...or will return just some detail about what might have gone right/wrong.
  | {
      detail?: string;
    }
  // ...or will have field and non-field errors to display
  | { [Property in keyof T]: string[] };

const handleUpdateModelInstance: <T>(
  props: THandleSubmitChangeModelInstance<T>
) => Promise<THandleSubmitChangeModelInstanceResult<T> | undefined> = async <
  T
>({
  endpointUrl,
  operation,
  actionStr,
  id,
  body,
}: THandleSubmitChangeModelInstance<T>) => {
  var result;
  var targetUrl;
  if (operation === "create") {
    targetUrl = getUrl({ endpointUrl, actionStr });
    result = await fetcherPatch<THandleSubmitChangeModelInstanceResult<T>>(
      targetUrl,
      body,
      "POST"
    );
    return result;
  } else if (["update", "destroy"].includes(operation)) {
    targetUrl = getUrl({ endpointUrl, pk: id, actionStr });

    if (operation === "destroy") {
      result = await fetcherPatch<THandleSubmitChangeModelInstanceResult<T>>(
        targetUrl,
        {},
        "DELETE"
      );
    } else {
      result = await fetcherPatch<THandleSubmitChangeModelInstanceResult<T>>(
        targetUrl,
        body,
        "PATCH"
      );
    }

    return result;
  }
};

// Now want some hooks: one for a single model instance and one for a list of
// model instance (like ListView and DetailView in DRF).
interface IUseApiModelDetailView<T> {
  // The object type for which we're requesting data, corresponding to one of
  // the main MASC API endpoints representing resources like "user",
  // "experiments", "projects", etc.
  resourceName: TResourceName;

  // The primary key ID of the single object we're getting.
  id?: number;

  // Automatically run the SWR bound mutate() on this object after creating,
  // updating, or destroying this object?
  mutateAfterChange?: boolean;

  // Unlikely that we won't want to get data for a read operation, but
  // leaving the switch here for more flexibility.
  shouldGetData?: boolean;

  // Explicit list of fields to retrieve in each record, to be passed as a
  // query param for DRF flexfields.
  fields?: (keyof T)[];

  // Explicit list of fields to omit from each record, to be passed as a query
  // param for DRF flexfields.
  omit?: (keyof T)[];

  // Explicit list of DRF-flexfield-expandable fields to that should be
  // expanded, to be passed as a query param for DRF flexfields.
  expand?: (keyof T)[];

  // Any additional query params that should be added to the end of the URL.
  queryParams?: Record<string, string>;
}

// Also want to be able to use the types of create, update, and destroy
// handlers elsewhere.
export type TCreateSingleModelInstance<T> = ({
  body,
}: Omit<
  IHandleCreateModelInstance<T>,
  "id" | "operation" | "endpointUrl"
>) => Promise<THandleSubmitChangeModelInstanceResult<T> | undefined>;

export type TUpdateSingleModelInstance<T> = ({
  body,
}: Omit<
  IHandleCreateModelInstance<T>,
  "id" | "operation" | "endpointUrl"
>) => Promise<THandleSubmitChangeModelInstanceResult<T> | undefined>;

export type TDestroySingleModelInstance = () => Promise<
  THandleSubmitChangeModelInstanceResult<unknown> | undefined
>;

export const useApiModelDetailView = <T>({
  resourceName,
  id,
  shouldGetData,
  mutateAfterChange = true,
  fields = undefined,
  omit = undefined,
  expand = undefined,
  queryParams = undefined,
}: IUseApiModelDetailView<T>) => {
  // Use SWR to read single record, if user said that we should be reading data.
  const targetUrl = getUrl({
    endpointUrl: resourceName,
    pk: id,
    queryParams: {
      ...(fields ? { fields: fields.join(",") } : undefined),
      ...(omit ? { omit: omit.join(",") } : undefined),
      ...(expand ? { expand: expand.join(",") } : undefined),
      ...queryParams,
    },
  });
  const { data, error, mutate } = useSWR<T>(() =>
    id && shouldGetData ? targetUrl : null
  );

  // Then, want individual handlers for updating stuff and optionally calling
  // SWR mutate() automatically.

  // Updater
  const updateSingleModelInstance: TUpdateSingleModelInstance<T> = useCallback(
    async ({ body }) => {
      var result;
      if (id) {
        result = await handleUpdateModelInstance({
          endpointUrl: resourceName,
          id,
          operation: "update",
          body,
        });
        if (mutateAfterChange) {
          await mutate();
        }
        return result;
      }
    },
    [id]
  );

  // Creator
  const createSingleModelInstance: TCreateSingleModelInstance<T> = useCallback(
    async ({ body }) => {
      var result;
      result = await handleUpdateModelInstance({
        endpointUrl: resourceName,
        operation: "create",
        body,
      });
      if (mutateAfterChange) {
        await mutate();
      }
      return result;
    },
    [id]
  );

  // Destroyer
  const destroySingleModelInstance: TDestroySingleModelInstance =
    useCallback(async () => {
      var result;
      if (id) {
        result = await handleUpdateModelInstance({
          endpointUrl: resourceName,
          id,
          operation: "destroy",
        });

        // Not going to mutate after deleting, even if user requested, because
        // object doesn't exist and thus can't be refetched.

        return result;
      }
    }, [id]);

  return {
    data,
    error,
    mutate,
    createSingleModelInstance,
    updateSingleModelInstance,
    destroySingleModelInstance,
  };
};

// Also want a hook for just retrieving the list of records
interface IUseApiModelListView<T> {
  // The object type for which we're requesting data, corresponding to one of
  // the main MASC API endpoints representing resources like "user",
  // "experiments", "projects", etc.
  resourceName: TResourceName;

  // Unlikely that we won't want to get data for a read operation, but
  // leaving the switch here for more flexibility.
  shouldGetData?: boolean;

  // Explicit list of fields to retrieve in each record, to be passed as a
  // query param for DRF flexfields.
  fields?: (keyof T)[];

  // Explicit list of fields to omit from each record, to be passed as a query
  // param for DRF flexfields.
  omit?: (keyof T)[];

  // Explicit list of DRF-flexfield-expandable fields to that should be
  // expanded, to be passed as a query param for DRF flexfields.
  expand?: (keyof T)[];

  // Any additional query params that should be added to the end of the URL.
  queryParams?: Record<string, string>;
}
export const useApiModelListView = <T, Paginated extends boolean = false>({
  resourceName,
  shouldGetData = false,
  fields = undefined,
  omit = undefined,
  expand = undefined,
  queryParams = undefined,
}: IUseApiModelListView<T>) => {
  // Use SWR to read single record, if user said that we should be reading data.
  const targetUrl = getUrl({
    endpointUrl: resourceName,
    queryParams: {
      ...(fields ? { fields: fields.join(",") } : undefined),
      ...(omit ? { omit: omit.join(",") } : undefined),
      ...(expand ? { expand: expand.join(",") } : undefined),
      ...queryParams,
    },
  });
  const { data, error, mutate } = useSWR<
    Paginated extends true ? PaginatedListResponse<T> : T[]
  >(() => (shouldGetData ? targetUrl : null));

  return { data, error, mutate };
};
