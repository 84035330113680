// Utilities for AJAX requests
// Currently just using the browser `fetch` interface.

import { getToken } from "./auth";

export type TFetcherGETError<T> = Error & {
  status?: number;
  info?: { [Property in keyof T]: string[] } | string;
  detail?: string;
};

const fetcher = async <T>(
  url: RequestInfo | URL | string,
  options?: RequestInit
) => {
  // If token if present, add it to auth header on requests.
  const token = getToken();
  // console.log(`Token is: ${token}`);
  // console.log(`Args: ${args}`);
  // console.log("[fetcher]: Token exists; using auth header.");
  const res = await fetch(url, {
    headers: {
      ...(options ? options.headers : undefined),
      ...(token ? { Authorization: `Token ${token}` } : undefined),
    },
  });

  // Throw error object if fetch fails for any reason.
  if (!res.ok) {
    const error: TFetcherGETError<T> = new Error(
      `Error during request to ${res.url}`
    );

    try {
      error.info = (await res.json()) as { [Property in keyof T]: string[] };
    } catch (err) {
      // If unable to parse JSON, just skip it.
    }
    error.status = res.status;

    throw error;
  }

  // At this point, request is successful. Now try parsing JSON.
  try {
    return (await res.json()) as T;
  } catch (err) {
    console.log("No JSON response, or unable to parse. Continuing silently.");
  }
};
export default fetcher;

export type TFetchGeneralError<T> = Error & {
  info?: { [Property in keyof T]: string[] } & {
    non_field_errors?: string[];
    detail?: string;
  };
  status?: number;
  detail?: string;
  non_field_errors?: string[];
};

export const fetcherPatch = async <T, R = T>(
  url: RequestInfo | URL | string,
  body: T,
  method: "PATCH" | "POST" | "PUT" | "GET" | "OPTIONS" | "DELETE" = "PATCH",
  additionalOptions?: RequestInit
) => {
  // Special fetcher just for updating things.

  const token = getToken();
  var authHeader = {};
  if (typeof token === "string") {
    authHeader = { Authorization: `Token ${token}` };
  }

  const res = await fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      ...(additionalOptions ? additionalOptions.headers : undefined),
      ...authHeader,
      "Content-Type": "application/json",
    },
  });

  // Handle general request errors

  if (!res.ok) {
    const error: TFetchGeneralError<R> = new Error(
      `Error with request to ${res.url}`
    );
    error.status = res.status;
    try {
      error.info = (await res.json()) as { [Property in keyof R]: string[] };
    } catch (err) {
      // If unable to parse JSON, just skip it.
    }
    throw error;
  }

  // At this point, request is successful. Now try parsing JSON. Response may
  // have extra fields, like "detail".
  try {
    return (await res.json()) as R & { detail?: string };
  } catch (err) {
    console.log("No JSON response, or unable to parse. Continuing silently.");
  }
};

// Also have a fetcher that posts multipart/formdata, for uploading files.
export const fetcherMultipartFormdata = async <R>(
  url: RequestInfo | URL | string,
  body: FormData,
  method: "PATCH" | "POST" | "PUT" | "GET" | "OPTIONS" | "DELETE" = "PATCH",
  additionalOptions?: RequestInit
) => {
  // Special fetcher just for updating things.

  const token = getToken();
  var authHeader = {};
  if (typeof token === "string") {
    authHeader = { Authorization: `Token ${token}` };
  }

  try {
    // Try request as normal, and turn any server errors into JavaScript errors
    // that we cna trap.
    let res = await fetch(url, {
      method,
      body,
      headers: {
        ...(additionalOptions ? additionalOptions.headers : undefined),
        ...authHeader,
        // We don't set Content-Type: multipart/form-data here, because browser
        // will do this automatically, using the multipart delimiter it chooses.
        // See
        // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest_API/Using_FormData_Objects#sect4.
      },
    });

    // Handle general request errors

    if (!res.ok) {
      const error: TFetchGeneralError<R> = new Error(
        `Error with request to ${res.url}`
      );
      error.status = res.status;
      try {
        error.info = (await res.json()) as { [Property in keyof R]: string[] };
      } catch (err) {
        // If unable to parse JSON, just skip it.
      }
      throw error;
    }

    // At this point, request is successful. Now try parsing JSON. Response may
    // have extra fields, like "detail".
    try {
      return (await res.json()) as R & { detail?: string };
    } catch (err) {
      console.log("No JSON response, or unable to parse. Continuing silently.");
    }
  } catch (err) {
    // However, if there is actually a client-side error with running the fetch
    // call, deal with it more directly.
    if (err instanceof TypeError) {
      // This can happen if the user is sending a request that is too large
      // (i.e., exceeds max client body size). In this case, throw a new
      // TFetchGeneralError with the same kinds of details as in the server
      // error case.
      const error: TFetchGeneralError<R> = new Error("Client-side error");

      // Use non-standard client error code
      error.status = 452;

      error.detail =
        "Request could not be initiated, and TypeError arose upon Fetch invocation.";

      throw error;
    } else {
      // Bubble up any other errors that aren't TypeErrors.
      throw err;
    }
  }
};
