// Some utilities for processing text

import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

// Capitalize first letter of string. Thanks to
// https://stackoverflow.com/a/1026087.
export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Truncate text with ellipsis
export const truncate = (input: string, maxLength: number) =>
  input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

// Map-like function to help make list of React nodes from object
// Thanks to https://stackoverflow.com/a/14810722
export const objectMap = (obj: {}, fn: (v: any, k: string, i: number) => any) =>
  Array.from(Object.entries(obj).map(([k, v], i) => fn(v, k, i)));

export const pluralizeByLength = (list: any[], pluralSuffix: string = "s") =>
  (list ?? []).length === 1 ? "" : pluralSuffix;

export const pluralizeByNumber = (
  value: number,
  pluralSuffix: string = "s",
  singleSuffix: string = ""
) => (value === 1 ? singleSuffix : pluralSuffix);

// Easily transpose matrix. Thanks to https://stackoverflow.com/a/46805290.
export function transpose(matrix: any[][]) {
  return matrix[0].map((col, i) => matrix.map((row) => row[i]));
}

// Rudimentary check to see if client is running macOS (i.e., whether we should
// use meta/super for keyboard shortcuts.)
export function isMacOs() {
  // window.navigator.platform is deprecated, so just look at user agent
  const macOsStrings = /(mac|iphone|ipad|ipod)/;
  return macOsStrings.test(navigator.userAgent.toLowerCase());
}

export function getCtrlKeySymbol() {
  if (isMacOs()) {
    return "\u2318";
  } else {
    return "Ctrl";
  }
}

// cn function to enable power-merging of Tailwind class names. See
// https://renatopozzi.me/articles/create-react-components-with-tailwind-like-a-pro
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Have a sleep function for async use. Thanks to https://stackoverflow.com/a/39914235.
export const sleepAsync = (ms: number) => new Promise((r) => setTimeout(r, ms));

// Convert number of bytes to human-readable form. Thanks to
// https://stackoverflow.com/a/18650828
export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "kiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

// Calculate median of an array of values. Thanks to
// https://stackoverflow.com/a/45309555.
export function median(values: number[]): number {
  if (values.length === 0) {
    throw new Error("Input array is empty");
  }

  // Sorting values, preventing original array
  // from being mutated.
  values = [...values].sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  return values.length % 2
    ? values[half]
    : (values[half - 1] + values[half]) / 2;
}

// Calculate fake UUID4 values (not cryptographically secure!). Thanks to
// https://stackoverflow.com/a/2117523.
export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
}
