// Editor component to edit and save plot data
import { useFormikContext } from "formik";

// Our form components
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/20/solid";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { FC, useContext, useMemo, useState } from "react";
import { Matrix } from "react-spreadsheet";
import * as Yup from "yup";
import {
  ButtonToolbarContainer,
  GenericButton,
} from "../../../../components/buttons";
import {
  FieldArrayInnerErrorMessage,
  MyCheckboxInputSideLabel,
  MyReactGridInput as MyReactGridInputBase,
  MySelectInput,
  MyTextInput,
} from "../../../../components/formik-form-components";
import { TabbedContent } from "../../../../components/tabbed-content";
import { TFetchGeneralError, fetcherPatch } from "../../../../lib/fetcher";
import { transpose } from "../../../../lib/text-utils";
import { FieldArrayExtraContext } from "./scatter-plots-form-main";

// Utility to remove zero or null padding from start and end of 2D arrays used
// with react-spreadsheet. This operates on rows (i.e., trim all rows that are
// filled with zeros, nulls, etc.). Based on
// https://www.geeksforgeeks.org/remove-leading-zeros-from-an-array/#.
export const removePadding2D: (args: {
  initialArray: Matrix<number | string | undefined>;
  axis: 0 | 1;
  how: "any" | "all";
}) => Matrix<number> = ({ initialArray, axis, how }) => {
  // Need axis and "how" params, like pandas.
  var arr;
  if (axis === 1) {
    arr = transpose(initialArray);
  } else {
    arr = initialArray;
  }

  var nr = arr.length;
  var idxStart = 0;
  var idxEnd = nr;

  // Test whether an element is null/undefined/zero/etc.
  const isBlank = (element: any) =>
    element === null ||
    typeof element === "undefined" ||
    (typeof element === "number" && element === 0) ||
    (typeof element === "string" && element === "");

  // Scan array from start to find first non-null row
  for (var i = 0; i < nr; i++) {
    // Start from beginning, discarding row only if all of its values are
    // null/undefined/zero, etc.
    if (!arr[i][how === "any" ? "some" : "every"](isBlank)) {
      idxStart = i;
      break;
    }
  }

  // Scan array from end to find last non-null column
  for (var i = nr - 1; i >= 0; i--) {
    if (!arr[i][how === "any" ? "some" : "every"](isBlank)) {
      idxEnd = i;
      break;
    }
  }

  // Now reconstruct array with only the valid rows
  var finalArray = [...arr.slice(idxStart, idxEnd + 1)];

  // Untranspose if we transposed earlier
  if (axis === 1) {
    finalArray = transpose(finalArray);
  }

  return finalArray;
};

// Validation for the data series part of the form
export const validationSchemaSingleDataSeries = Yup.object().shape({
  showLine: Yup.boolean().required("Must specify whether to show a line"),
  lineThickness: Yup.number().optional(),
  name: Yup.string()
    .required("Must have a name")
    .min(3, "Name must be at least 3 characters"),
  color: Yup.string()
    .optional()
    .min(7, "Must specify hex color, including hash symbol"),
  fillPoints: Yup.boolean().required("Must specify whether points are filled"),
  shape: Yup.string()
    .optional()
    .oneOf(
      [
        "false",
        "circle",
        "cross",
        "star",
        "triangle",
        "crossRot",
        "dash",
        "line",
        "rect",
        "rectRounded",
        "rectRot",
      ],
      'Select a marker option, or "None" for no markers.'
    ),
  xdata: Yup.array()
    .of(Yup.number())
    .required("Must have X axis data.")
    .min(1, "Must have at least 1 data point."),
  ydata: Yup.array()
    .of(Yup.number())
    .required("Must have Y axis data.")
    .min(1, "Must have at least 1 data point."),
  secondYAxis: Yup.boolean().required(
    "Must specify whether series is on second Y axis."
  ),
});

// Handle deletion of single series
export const pushDeleteSingleSeries = async (
  series: IScatterPlotDataSeries
) => {
  const id = series.id;
  await fetcherPatch(
    `${import.meta.env.VITE_API_URL}/api/v1/scatter_plot_data_series/${id}/`,
    {},
    "DELETE"
  );
};

const DataSeriesEditor: FC<{ data_series: IScatterPlotDataSeries[] }> = ({
  data_series,
}) => {
  const { fieldArrayHelpers } = useContext(FieldArrayExtraContext);
  const { swap, remove, push } = fieldArrayHelpers;
  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  // Pull out initial values from Formik context so we can get parent form ID
  const { initialValues } = useFormikContext<IScatterPlot>();

  const MyReactGridInput = useMemo(() => MyReactGridInputBase, []);

  // Build up tabbed content for data series editor
  const addNewItem: ISingleTabbedContentItem = {
    idx: -1,
    displayName: (
      <div className="flex items-center gap-x-2">
        <div className="flex items-center justify-center flex-none">
          <FontAwesomeIcon className="w-5 h-5" icon={faPlusCircle} />
        </div>
        <span>New data series...</span>
      </div>
    ),
    element: <div>New data series?</div>,
    callback: () => {
      push({
        id: NaN,
        name: `Untitled ${data_series.length}`,
        showLine: true,
        lineThickness: 1,
        color: "#000000",
        fillPoints: true,
        shape: "false",
        xdata: [0],
        ydata: [0],
        secondYAxis: false,
        parent_plot: initialValues.id,
      } as IScatterPlotDataSeries);
      setTabActiveIndex(data_series.length);
    },
  };
  const series_contents: ISingleTabbedContentItem[] = data_series.map(
    (series, idx) => ({
      idx,
      displayName: series.name,
      callback: () => {
        // Want to push active tab state back to parent so we always go to
        // correct tab after deleting one.
        setTabActiveIndex(idx);
      },
      element: (
        <>
          <div className="flex flex-wrap items-stretch">
            {/* Swap/delete toolbar */}
            <span className="px-3 py-2 text-sm font-semibold text-slate-700 dark:text-slate-400">
              Data series operations:
            </span>
            <ButtonToolbarContainer>
              <GenericButton
                tooltip_text="Move series to earlier position in legend."
                tooltip_text_disabled="Series is already in first position; cannot move earlier."
                icon={<ArrowLeftIcon className="w-4 h-4" />}
                disabled={idx === 0}
                onClick={() => {
                  if (idx > 0) {
                    swap(idx, idx - 1);
                    setTabActiveIndex(idx - 1);
                  }
                }}
              >
                Shift left
              </GenericButton>
              <GenericButton
                tooltip_text="Move series to later position in legend."
                tooltip_text_disabled="Series is already in last position; cannot move later."
                icon={<ArrowRightIcon className="w-4 h-4" />}
                disabled={idx === data_series.length - 1}
                onClick={() => {
                  if (idx !== data_series.length - 1) {
                    swap(idx, idx + 1);
                    setTabActiveIndex(idx + 1);
                  }
                }}
              >
                Shift right
              </GenericButton>
              <GenericButton
                tooltip_text="Remove this data series from the plot."
                icon={<TrashIcon className="w-4 h-4" />}
                onClick={async () => {
                  // Delete current series on server, then remove from plot
                  try {
                    await pushDeleteSingleSeries(data_series[idx]);
                  } catch (err) {
                    if (err instanceof Error) {
                      let e: TFetchGeneralError<{}> = err;
                      console.log(e);
                      if (e.status === 404) {
                        console.log(
                          "Tried to delete non-existing data series on server. Continuing silently"
                        );
                      }
                    } else {
                      console.log(
                        "Tried unsuccessfully to delete data series."
                      );
                    }
                  }
                  remove(idx);
                  if (idx !== 0) {
                    setTabActiveIndex(idx - 1);
                  } else if (idx === data_series.length) {
                    setTabActiveIndex(0);
                  }
                }}
              >
                Delete
              </GenericButton>
            </ButtonToolbarContainer>
            <div className="flex flex-col w-1/2 px-2 gap-y-2">
              <MyTextInput
                type="text"
                label="Series name"
                name={`data_series.${idx}.name`}
              />
              <MyCheckboxInputSideLabel
                label="Plot on 2nd Y axis?"
                name={`data_series.${idx}.secondYAxis`}
                type="checkbox"
              />
              <MyCheckboxInputSideLabel
                label="Filled points?"
                name={`data_series.${idx}.fillPoints`}
                type="checkbox"
              />
              <MyTextInput
                label="Color"
                name={`data_series.${idx}.color`}
                type="color"
                className="h-5"
              />
              <div className="flex gap-x-2">
                <MySelectInput
                  label="Marker shape"
                  type="text"
                  name={`data_series.${idx}.shape`}
                  choices={[
                    { id: "false", name: "None" },
                    { id: "circle", name: "Circle" },
                    { id: "cross", name: "Cross" },
                    { id: "star", name: "Star" },
                    { id: "triangle", name: "Triangle" },
                    { id: "crossRot", name: "Rotated Cross" },
                    { id: "dash", name: "Dash" },
                    { id: "line", name: "Line" },
                    { id: "rect", name: "Rectangle" },
                    { id: "rectRounded", name: "Rounded Rectangle" },
                    { id: "rectRot", name: "Rotated Rectangle" },
                  ]}
                />
                <MyTextInput
                  label="Point size"
                  type="number"
                  name={`data_series.${idx}.pointRadius`}
                />
              </div>
              <MyCheckboxInputSideLabel
                label="Connect points with line?"
                name={`data_series.${idx}.showLine`}
                type="checkbox"
              />

              <MyTextInput
                type="number"
                label="Line thickness (pt)"
                name={`data_series.${idx}.lineThickness`}
              />
            </div>
            <div className="w-1/2 px-2 gap-y-2">
              <MyReactGridInput
                label="Edit data"
                name={`data_series.${idx}`}
                idx={idx}
              />
              <FieldArrayInnerErrorMessage name={`data_series.${idx}.xdata`} />
              <FieldArrayInnerErrorMessage name={`data_series.${idx}.ydata`} />
            </div>
          </div>
        </>
      ),
    })
  );

  const tabbed_contents = [...series_contents, addNewItem];

  return (
    <TabbedContent content={tabbed_contents} activeIndex={tabActiveIndex} />
  );
};

export default DataSeriesEditor;
