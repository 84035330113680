// Component to show different content views with tab navigation at top
import { FC, useCallback, useEffect, useState } from "react";
import LoadingSpinnerInline from "./spinners";

export const TabbedContent: FC<ITabbedContentProps> = ({
  content,
  activeIndex,
}) => {
  const [activeItem, setActiveItem] = useState<number>(
    activeIndex ? activeIndex : content[0].idx
  );

  // Also handle imperative update to tab index
  useEffect(() => {
    if (typeof activeIndex !== "undefined" && activeItem !== activeIndex) {
      setActiveItem(activeIndex);
    }
  }, [activeIndex]);

  // Need to establish click handler to switch state
  const handleTabClick = useCallback(
    (item: ISingleTabbedContentItem) => {
      // If there is a callback registered, call it.
      if ("callback" in item && typeof item.callback === "function") {
        item.callback();
      } else {
        setActiveItem(item.idx);
      }
    },
    [activeIndex]
  );

  const isActive = useCallback((id: number) => id === activeItem, [activeItem]);

  var activeDisplayElement: JSX.Element | null;

  try {
    activeDisplayElement = content.filter((item) => isActive(item.idx))[0]
      .element;
  } catch (err) {
    activeDisplayElement = null;
  }

  return content ? (
    <div className="container">
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        {content.map((contentItem: ISingleTabbedContentItem, idx) => (
          <li
            key={idx}
            className="mr-2"
            onClick={() => handleTabClick(contentItem)}
          >
            <a
              href="#"
              aria-current="page"
              className={
                (isActive(contentItem.idx)
                  ? "text-white bg-slate-500"
                  : "hover:bg-slate-300") +
                " inline-block px-3 py-2 my-3 rounded-lg dark:bg-gray-800 dark:text-blue-500"
              }
            >
              {contentItem?.displayName
                ? contentItem?.displayName
                : contentItem.idx}
            </a>
          </li>
        ))}
      </ul>
      {/* Now show content area */}
      <div className="container px-3 py-2">{activeDisplayElement}</div>
    </div>
  ) : (
    <div>
      <LoadingSpinnerInline />
    </div>
  );
};
