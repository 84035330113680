// Utilities for user login, logout, and authentication
import { useState } from "react";
import useSWR from "swr";
import {
  IPasswordResetConfirmInput,
  IPasswordResetConfirmResponse,
} from "../pages/login-auth-registration/password-reset-confirm";
import { IPasswordResetRequestInput } from "../pages/login-auth-registration/password-reset-request";
import { IRegistrationInput } from "../pages/login-auth-registration/registration";
import { IRequestNewVerificationEmailResponse } from "../pages/login-auth-registration/request-new-verification-email";
import {
  IVerifyKeyInput,
  IVerifyKeyResponse,
} from "../pages/login-auth-registration/verify-email";
import { useApiModelDetailView } from "./api-mutate-hooks";
import { fetcherPatch } from "./fetcher";

// Some other code thanks to
// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications#prerequisites

// Set up hook to get and set session token
export type TUserToken = {
  expiry: string;
  token: string;
};

export const getToken = () => {
  const tokenString = localStorage.getItem("token");

  if (tokenString === null || typeof tokenString === "undefined") {
    return false;
  } else {
    const userToken: TUserToken = JSON.parse(tokenString);
    if (typeof userToken === "undefined") {
      return false;
    }
    return userToken.token;
  }
};

const saveToken = (userToken: TUserToken) => {
  localStorage.setItem("token", JSON.stringify(userToken));
};

export default function useToken() {
  const [token, setToken] = useState(getToken());

  const hookSaveToken = (userToken: TUserToken) => {
    setToken(userToken.token);
    saveToken(userToken);
  };

  return { token, setToken: hookSaveToken };
}

export type TKnoxCurrentKnoxUser = {
  email: string;
  detail?: string;
  pk: number;
  first_name: string;
  last_name: string;
};

interface IUseCurrentKnoxUser {
  // If false, don't attempt to get Knox user information even if a token is
  // present. Use this to reduce the number of requests in a form where the
  // Knox user info is conditionally required.
  shouldGetData?: boolean;
}
export const useCurrentKnoxUser = ({
  shouldGetData = true,
}: IUseCurrentKnoxUser) => {
  const { token } = useToken();
  const {
    data: currentKnoxUser,
    error: currentKnoxUserError,
    mutate,
  } = useSWR<TKnoxCurrentKnoxUser>(() =>
    token && shouldGetData
      ? `${import.meta.env.VITE_API_URL}/api/knox/user/`
      : null
  );

  return {
    currentKnoxUser,
    currentKnoxUserError,
    mutate,
  };
};

// Also have convenience wrapper hook to just get full user info quickly
interface IUseFullCurrentUser {
  shouldGetData?: boolean;
}
export const useFullCurrentUser = ({
  shouldGetData = false,
}: IUseFullCurrentUser) => {
  const { currentKnoxUser, currentKnoxUserError } = useCurrentKnoxUser({
    shouldGetData,
  });

  // Dependent query to get full user info
  const {
    data: fullUser,
    error: fullUserError,
    mutate: fullUserMutate,
  } = useApiModelDetailView<IUser>({
    resourceName: "users",
    id: currentKnoxUser?.pk,
    shouldGetData: !!(currentKnoxUser && !currentKnoxUserError),
  });

  return { fullUser, fullUserError, fullUserMutate };
};

interface ILoginUser {
  username: string;
  password: string;
}
interface ILoginUserResponse extends TUserToken {
  non_field_errors?: string[];
}

export async function loginUser(creds: ILoginUser) {
  // var res = await fetch(`${import.meta.env.VITE_API_URL}/api/knox/login/`, {
  //   method: "POST",
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify(creds),
  // });

  // var data = await res.json();

  const data = await fetcherPatch<ILoginUser, ILoginUserResponse>(
    `${import.meta.env.VITE_API_URL}/api/knox/login/`,
    creds,
    "POST"
  );

  console.log("[loginUser] data:", data);

  if (data && Object.hasOwnProperty.call(data, "token")) {
    saveToken(data);
  }

  return data;
}

export interface ILogoutUserArgs {
  type: "this_device" | "all_devices";
}
export async function logoutUser(args: ILogoutUserArgs) {
  var token = getToken();
  var url;
  if (args.type === "all_devices") {
    url = `${import.meta.env.VITE_API_URL}/api/knox/logoutall/`;
  } else {
    url = `${import.meta.env.VITE_API_URL}/api/knox/logout/`;
  }
  var res = await fetch(url, {
    method: "POST",
    headers: { Authorization: `Token ${token}` },
  });

  var data = await res.json();

  // Clear local token if logout was successful
  if (data.message.toLowerCase().includes("logged out")) {
    localStorage.removeItem("token");
  }
  return data;
}

// Post registration information to the API endpoint to register a new user
interface IRegistrationResponse
  extends Partial<IRegistrationInput & { id?: number }> {}
export async function registerNewUser(values: IRegistrationInput) {
  // Assume that validation has been done at the form level and post directly to API endpoint.

  // Attempt to register users, and allow errors to flow up to the form
  // component.
  var result_json = await fetcherPatch<IRegistrationResponse>(
    `${import.meta.env.VITE_API_URL}/api/registration/register/`,
    values,
    "POST"
  );

  return result_json;
}

// Post email HMAC verification key to email confirmation endpoint
export async function confirmEmail(values: IVerifyKeyInput) {
  var result_json: IVerifyKeyResponse | undefined = {
    error: ["Irrecoverable error"],
  };
  try {
    result_json = await fetcherPatch(
      `${import.meta.env.VITE_API_URL}/api/registration/verify-email/`,
      values,
      "POST"
    );
  } catch (err) {
    // Not sure what the error is. Log it.
    console.log(
      "[registration] Error posting to email verification endpoint",
      err
    );
  }

  return result_json;
}

// Also have wrapper to resend confirmation email

export async function resendEmail(values: IVerifyKeyInput) {
  var result_json = await fetcherPatch<IVerifyKeyInput, IVerifyKeyResponse>(
    `${import.meta.env.VITE_API_URL}/api/registration/resend-email-from-hmac/`,
    values,
    "POST"
  );

  return result_json;
}

// And the option to resend the email confirmation using just the user's email address
interface IResendEmailFromEmailAddressInput {
  email: string;
}

export async function resendEmailFromEmailAddress(
  values: IResendEmailFromEmailAddressInput
): Promise<IRequestNewVerificationEmailResponse | undefined> {
  let result_json = await fetcherPatch<
    IResendEmailFromEmailAddressInput,
    IRequestNewVerificationEmailResponse
  >(
    `${import.meta.env.VITE_API_URL}/api/registration/resend-email/`,
    values,
    "POST"
  );
  return result_json;
}

// Send password reset confirmation form
export async function confirmPasswordReset(values: IPasswordResetConfirmInput) {
  let result_json = await fetcherPatch<
    IPasswordResetConfirmInput,
    IPasswordResetConfirmResponse
  >(
    `${import.meta.env.VITE_API_URL}/api/registration/password/reset/confirm/`,
    values,
    "POST"
  );
  return result_json;
}

// Send request to let user request a new password reset
// Send password reset confirmation form
export async function requestPasswordReset(values: IPasswordResetRequestInput) {
  var result_json = await fetcherPatch(
    `${import.meta.env.VITE_API_URL}/api/registration/password/reset/`,
    values,
    "POST"
  );

  return result_json;
}
