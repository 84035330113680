// Utilities for printing dates
import { format, formatDistance, parseISO } from "date-fns";
import { FC } from "react";

export const DateWithDistance: FC<{ theDate: string | undefined }> = ({
  theDate,
}) => {
  return theDate ? (
    <>
      {format(new Date(theDate.replace(/-/g, "/")), "dd MMM yyyy")} (
      <span className="italic">
        {formatDistance(new Date(theDate.replace(/-/g, "/")), new Date(), {
          addSuffix: true,
        })}
      </span>
      )
    </>
  ) : (
    <span className="italic">(none)</span>
  );
};

export const MyDateFormat: FC<{ theDate: string | undefined }> = ({
  theDate,
}) => {
  return theDate ? (
    <>{format(new Date(theDate.replace(/-/g, "/")), "dd MMM yyyy")}</>
  ) : (
    <span className="italic">(no date)</span>
  );
};

export const MyDateTimeFormat: FC<{
  theDateTime: string | undefined;
  showDistance?: boolean;
}> = ({ theDateTime, showDistance = true }) => {
  if (!!theDateTime) {
    const parsedDate = parseISO(theDateTime);
    return (
      <>
        {format(parsedDate, "dd MMM yyyy, h:mm a")}
        {showDistance
          ? " (" +
            formatDistance(parsedDate, new Date(), { addSuffix: true }) +
            ")"
          : ""}
      </>
    );
  } else {
    return <span className="italic">(no date)</span>;
  }
};

export const dateToJsonStr = (d: Date | string): string => {
  const theDate = d instanceof Date ? d : new Date(d);
  return format(theDate, "MM-dd-yyyy");
};
