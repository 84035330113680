// Toasts and other message indicators for flash messages, status messages, etc.

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Link } from "react-router-dom";
import { useApiModelDetailView } from "../lib/api-mutate-hooks";
import { MyDateTimeFormat } from "../lib/date-utils";
import { TFetchGeneralError } from "../lib/fetcher";
import { capitalize, cn, formatBytes } from "../lib/text-utils";
import { GenericButton } from "./buttons";

interface ISimpleToast {
  message: string | JSX.Element;
  iconStroke?: string;
  iconBg?: string;
  icon: JSX.Element;
  className?: string;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  showCloseBtn?: boolean;
}
export const SimpleToastControlled: FC<ISimpleToast> = ({
  message,
  iconStroke = "text-blue-500 dark:text-blue-200",
  iconBg = "bg-blue-100 dark:bg-blue-800",
  icon,
  className = "",
  isVisible = true,
  setIsVisible,
  showCloseBtn = true,
}) => {
  return isVisible ? (
    <div
      className={cn(
        "flex items-center w-full max-w-lg p-2 text-slate-500 bg-white rounded-lg shadow dark:text-slate-400 dark:bg-slate-800 gap-x-4",
        className
      )}
      role="alert"
    >
      <div className="flex flex-col justify-start h-full">
        <div
          className={cn(
            "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg",
            iconStroke,
            iconBg
          )}
        >
          {icon}
          <span className="sr-only">Icon</span>
        </div>
      </div>
      <div className="text-sm font-normal">{message}</div>

      {/* Blank div to force close button to right */}
      <div className="grow"></div>

      {showCloseBtn ? (
        <div className="flex flex-col justify-start h-full">
          <button
            type="button"
            className="bg-white text-slate-400 hover:text-slate-900 rounded-lg focus:ring-2 focus:ring-slate-300 p-1.5 hover:bg-slate-100 inline-flex items-center justify-center h-8 w-8 dark:text-slate-500 dark:hover:text-white dark:bg-slate-800 dark:hover:bg-slate-700"
            onClick={() => setIsVisible(false)}
            aria-label="Close"
          >
            <span className="sr-only">Close</span>
            <FontAwesomeIcon
              icon={faClose}
              className="w-4 h-4 text-slate-600 dark:text-slate-400"
            />
          </button>
        </div>
      ) : null}
    </div>
  ) : null;
};

// Want a way to easily show field errors from error objects thrown by fetch
// requests.
export interface IMascApiFetchErrorsTypeset<T> {
  error: TFetchGeneralError<T>;
  errorTransformer?: (err: TFetchGeneralError<T>) => JSX.Element[];
}

export const MascApiFetchErrorsTypeset = <T,>({
  error,
  errorTransformer = undefined,
}: IMascApiFetchErrorsTypeset<T>) => {
  const [showFullErrors, setShowFullErrors] = useState(false);
  let additionalErrMsgs: JSX.Element[] = [];
  if (errorTransformer) {
    additionalErrMsgs = errorTransformer(error).map((elem, idx) => (
      <div key={idx} className="mt-2">
        {elem}
      </div>
    ));
  }
  return (
    <div>
      <p>Please correct the following errors:</p>
      {error.info &&
      typeof error.info === "object" &&
      typeof error.info !== "string"
        ? // We have field-level errors
          Object.keys(error.info).map((key, idx: number) => {
            let theKey: keyof typeof error.info =
              key as keyof typeof error.info;
            return theKey !== "detail" ? (
              <ul key={idx} className="ml-4 list-disc">
                <li>{capitalize(String(theKey))}:</li>
                {error.info &&
                typeof error.info === "object" &&
                typeof error.info !== "string" ? (
                  <ul className="ml-4 list-[circle]">
                    {error.info[theKey].constructor === Array
                      ? error.info[theKey].map((item: string, idx2: number) => (
                          <li key={idx2}>{capitalize(item)}</li>
                        ))
                      : null}
                  </ul>
                ) : null}
              </ul>
            ) : null;
          })
        : null}
      {error?.info?.detail ? (
        <>
          <ul className="ml-4 list-disc" key="other-details">
            <li>{error.info.detail}</li>
          </ul>
        </>
      ) : null}
      {error?.detail ? (
        <>
          <ul className="ml-4 list-disc" key="other-details">
            <li>{error.detail}</li>
          </ul>
        </>
      ) : null}
      {additionalErrMsgs.length > 0 ? <>{additionalErrMsgs}</> : null}
      {/* If email is unverified, show user link to verify it */}
      {error.info?.non_field_errors &&
      error.info.non_field_errors[0] === "E-mail is not verified." ? (
        <div className="flex flex-col gap-x-2">
          <div>You registered, but never verified your email address.</div>
          <Link
            to="/registration/verify-email-request/"
            className="font-medium link hover:underline"
          >
            Resend verification email.
          </Link>
        </div>
      ) : null}
      <div>
        <div className="flex mt-2">
          <GenericButton
            type="button"
            onClick={() => setShowFullErrors((prev) => !prev)}
            className="h-6 px-2 text-xs link"
          >
            {showFullErrors ? "Hide" : "Show"} full errors
          </GenericButton>
        </div>
        {showFullErrors ? (
          <div className="max-w-full break-all">
            {" "}
            <p className="mt-1 font-mono text-xs">{error.toString()}</p>{" "}
            <p className="mt-1 font-mono text-xs">{error?.detail}</p>{" "}
            <p className="mt-1 font-mono text-xs">{error?.message}</p>{" "}
            <p className="mt-1 font-mono text-xs">
              Status code: {error?.status}
            </p>{" "}
            <p className="mt-1 font-mono text-xs">
              {JSON.stringify(error?.info, null, 2)}
            </p>
            <p className="mt-1 font-mono text-xs">
              {JSON.stringify(error?.non_field_errors, null, 2)}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

interface IStorageUsedIndicator {
  totalFileSize: number;
  maxTotalFileSize: number;
}
export const StorageUsedIndicator: FC<IStorageUsedIndicator> = ({
  totalFileSize,
  maxTotalFileSize,
}) => {
  const percentageUsed = (totalFileSize / maxTotalFileSize) * 100;
  return (
    <div className="flex items-center mt-4 gap-x-4">
      <span className="inline-block">Storage used:</span>
      <div className="relative w-32 h-5 p-0 border rounded-lg bg-slate-50 dark:bg-slate-50/10 border-slate-500 overflow-clip">
        <div
          className={cn(
            "h-full bg-green-300 dark:bg-green-300/50",
            percentageUsed > 50 ? "bg-yellow-300 dark:bg-yellow-300/50" : "",
            percentageUsed > 75 ? "bg-orange-200 dark:bg-orange-200/50" : "",
            percentageUsed > 85 ? "bg-red-300 dark:bg-red-300/50" : ""
          )}
          style={{ width: `${percentageUsed}%` }}
        ></div>
        <div className="absolute p-0 text-sm font-semibold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          {percentageUsed.toFixed(0)}%
        </div>
      </div>
      <div className="italic font-medium text-slate-600 dark:text-slate-400">
        Total: {formatBytes(totalFileSize, 2)} / {formatBytes(maxTotalFileSize)}
      </div>
    </div>
  );
};

interface ICreatedModifiedAtByIndicator {
  object: Required<ICreatedModifiedAtByFields> & Record<string, any>;
  className?: string;
}
export const CreatedModifiedAtByIndicator: FC<
  ICreatedModifiedAtByIndicator
> = ({ object, className }) => {
  // Need to get users by which record was created and modified
  const { created_at, created_by, modified_at, modified_by } = object;
  interface IUserPartial
    extends Pick<IUser, "id" | "username" | "first_name" | "last_name"> {}
  const { data: createdUser, error: createdUserError } =
    useApiModelDetailView<IUserPartial>({
      id: created_by!,
      resourceName: "users",
      shouldGetData: !!created_by,
      fields: ["id", "username", "first_name", "last_name"],
    });
  const { data: modifiedUser, error: modifiedUserError } =
    useApiModelDetailView<IUserPartial>({
      id: modified_by!,
      resourceName: "users",
      shouldGetData: !!modified_by,
      fields: ["id", "username", "first_name", "last_name"],
    });
  return (
    <div
      className={cn("text-xs text-slate-600 dark:text-slate-400", className)}
    >
      <p>
        {" "}
        Created: <MyDateTimeFormat theDateTime={created_at} />{" "}
        {createdUser && !createdUserError ? (
          <span>
            by{" "}
            <Link
              to={`/users/${createdUser?.id}/`}
              className="font-medium link"
            >
              {createdUser?.first_name} {createdUser?.last_name} (@
              {createdUser?.username})
            </Link>
          </span>
        ) : null}
      </p>
      <p>
        {" "}
        Last updated: <MyDateTimeFormat theDateTime={modified_at} />{" "}
        {modifiedUser && !modifiedUserError ? (
          <span>
            by{" "}
            <Link
              to={`/users/${modifiedUser?.id}/`}
              className="font-medium link"
            >
              {modifiedUser?.first_name} {modifiedUser?.last_name} (@
              {modifiedUser?.username})
            </Link>
          </span>
        ) : null}
      </p>
    </div>
  );
};
