// Wrapper components to provide Chart.js plot viewers
import {
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LineElement,
  LinearScale,
  LogarithmicScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

import { FC } from "react";
import { Scatter } from "react-chartjs-2";

// Register Chart.js components
ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  LinearScale,
  LogarithmicScale,
  Tooltip,
  Legend,
  Title
);

// Set default options
ChartJS.defaults.font = {
  ...ChartJS.defaults.font,
  family: "system-ui",
  size: 16,
};

// Utility to zip lists like in Python
// Thanks to https://stackoverflow.com/a/10284006
export function zip<T>(...args: T[][]): T[][] {
  var shortest =
    args.length == 0
      ? []
      : args.reduce<T[]>(function (a: T[], b: T[]) {
          return a.length < b.length ? a : b;
        }, args[0]);

  return shortest.map(function (_, i) {
    return args.map(function (array) {
      return array[i];
    });
  });
}

// Transform data object from format used in our database to format that can be
// dropped into any of our chart components.
function zipXYData(series_data: ISingleDataSeries) {
  // For now, basically just need to get the data for the x and y axes zipped
  const zipped_data = zip(series_data.xdata, series_data.ydata).map(
    (value, _) => {
      return { x: value[0], y: value[1] };
    }
  );

  return zipped_data;
}

// Same zip function as above, but adapted for the normalized scatter plot API.
function zipXYDataNormalized(series_data: IScatterPlotDataSeries) {
  // For now, basically just need to get the data for the x and y axes zipped
  const zipped_data = zip(series_data.xdata, series_data.ydata).map(
    (value, _) => {
      return { x: value[0], y: value[1] };
    }
  );

  return zipped_data;
}

// Adaptor to transform our data to Chart.JS scatter plot input
// DEPRECATED: not being used anymore in the new normalized plot model.
export function plotJsonToChartJSInput(inputData: ISinglePlotJsonData): {
  options: ChartOptions<"scatter">;
  data: ChartData<"scatter">;
} {
  const options: ChartOptions<"scatter"> = {
    plugins: {
      title: {
        display: true,
        position: "top",
        align: "center",
        text: inputData.meta.name,
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      legend: {
        position: "top",
        title: {
          display: false,
          text: "My legend",
        },
      },
    },
    animation: false,

    scales: {
      x: {
        title: { display: true, text: inputData.x_axis.label },
        type: inputData.x_axis.scale === "log" ? "logarithmic" : "linear",
      },
      yAxisLeft: {
        display: "auto",
        beginAtZero: false,
        title: { display: true, text: inputData.y_axis.label },
        type: inputData.y_axis.scale === "log" ? "logarithmic" : "linear",
        position: "left",
      },
      yAxisRight: {
        display: "auto",
        beginAtZero: false,
        title: {
          display: true,
          text: inputData?.y_axis_2?.label,
          color: "#396b92",
        },
        type: inputData.y_axis_2?.scale === "log" ? "logarithmic" : "linear",
        ticks: { color: "#396b92" },
        grid: { color: "#a1bacb" },
        position: "right",
      },
    },
  };

  const data: ChartData<"scatter"> = {
    datasets: inputData.dataseries.map((single_series: ISingleDataSeries) => ({
      yAxisID: single_series.secondYAxis ? "yAxisRight" : "yAxisLeft",
      label: single_series.name,
      pointStyle: single_series.shape,
      pointRadius: single_series.pointRadius,
      data: zipXYData(single_series),
      backgroundColor: single_series.color,
      borderColor: single_series.color,
      showLine: single_series.showLine,
    })),
  };

  return { options, data };
}

// DEPRECATED: not being used anymore in the normalized plot viewer
export const PlotViewerChartJS: FC<{
  inputData: ISinglePlotJsonData;
  showTitle?: boolean;
}> = ({ inputData, showTitle = true }) => {
  const { options, data } = plotJsonToChartJSInput(inputData);

  if (typeof options?.plugins?.title?.display !== "undefined") {
    options.plugins.title.display = showTitle;
  }

  return (
    <div className="w-full">
      <Scatter options={options} data={data} redraw={false} updateMode="none" />
    </div>
  );
};

// Adaptor to transform our data to Chart.JS scatter plot input
export function plotJsonToChartJSInputNormalized(
  inputData: IScatterPlotWithDataSeries
): {
  options: ChartOptions<"scatter">;
  data: ChartData<"scatter">;
} {
  const options: ChartOptions<"scatter"> = {
    plugins: {
      title: {
        display: true,
        position: "top",
        align: "center",
        text: inputData.name,
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      legend: {
        position: "top",
        title: {
          display: false,
          text: "My legend",
        },
      },
    },
    animation: false,

    scales: {
      x: {
        title: { display: true, text: inputData.x_axis_name },
        type: inputData.x_axis_scale === "log" ? "logarithmic" : "linear",
      },
      yAxisLeft: {
        display: "auto",
        beginAtZero: false,
        title: { display: true, text: inputData.y_axis_name },
        type: inputData.y_axis_scale === "log" ? "logarithmic" : "linear",
        position: "left",
      },
      yAxisRight: {
        display: "auto",
        beginAtZero: false,
        title: {
          display: true,
          text: inputData?.y_axis_2_name,
          color: "#396b92",
        },
        type: inputData.y_axis_2_scale === "log" ? "logarithmic" : "linear",
        ticks: { color: "#396b92" },
        grid: { color: "#a1bacb" },
        position: "right",
      },
    },
  };

  // Want to render data series in the same order as specified in the scatter
  // plot record. Check if the "data_series_order" field is set and matches the IDs of the plots we're using.
  let dataSeriesToUse = getOrderedDataSeries(inputData);

  const data: ChartData<"scatter"> = {
    datasets: dataSeriesToUse.map((single_series: IScatterPlotDataSeries) => ({
      yAxisID: single_series.secondYAxis ? "yAxisRight" : "yAxisLeft",
      label: single_series.name,
      pointStyle: single_series.shape === "false" ? false : single_series.shape,
      pointRadius: single_series.pointRadius,
      data: zipXYDataNormalized(single_series),
      backgroundColor: single_series.color,
      borderColor: single_series.color,
      borderWidth: single_series.lineThickness,
      showLine: single_series.showLine,
    })),
  };

  return { options, data };
}

export const PlotViewerChartJSNormalized: FC<{
  inputData: IScatterPlotWithDataSeries;
  showTitle?: boolean;
}> = ({ inputData, showTitle = true }) => {
  const { options, data } = plotJsonToChartJSInputNormalized(inputData);

  if (typeof options?.plugins?.title?.display !== "undefined") {
    options.plugins.title.display = showTitle;
  }

  return (
    <div className="w-full">
      <Scatter options={options} data={data} redraw={false} updateMode="none" />
    </div>
  );
};

export function getOrderedDataSeries(inputData: IScatterPlotWithDataSeries) {
  const child_data_series_ids = new Set(
    inputData.data_series.map(
      (single_series: IScatterPlotDataSeries) => single_series.id
    )
  );

  const ordering_data_series_ids = new Set(inputData.data_series_order);

  const shouldUseStoredOrder =
    child_data_series_ids.size === ordering_data_series_ids.size &&
    [...child_data_series_ids].every((x: number) =>
      ordering_data_series_ids.has(x)
    );

  let dataSeriesToUse;
  if (shouldUseStoredOrder) {
    dataSeriesToUse = Array.from(ordering_data_series_ids).map(
      (id: number) =>
        inputData.data_series.filter(
          (series: IScatterPlotDataSeries) => series.id === id
        )[0]
    );
  } else {
    dataSeriesToUse = inputData.data_series;
  }
  return dataSeriesToUse;
}
