import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldHookConfig, useField, useFormikContext } from "formik";
import {
  ChangeEvent,
  ClassAttributes,
  Dispatch,
  FC,
  HtmlHTMLAttributes,
  InputHTMLAttributes,
  Ref,
  SelectHTMLAttributes,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Matrix, Spreadsheet } from "react-spreadsheet";
import { cn } from "../lib/text-utils";
import { PlotEditorFormExtraContext } from "../pages/experiments/characterization-results/scatter-plots/scatter-plots-form-main";
import { xyArraysToMatrixValues } from "./datagrids/react-spreadsheet-wrappers";
import { FeaturedReactGrid, IDataPoint } from "./datagrids/reactgrid-wrappers";

// Adapted single field debouncing thing from
// https://stackoverflow.com/a/69919727 and from @tanstack/react-table examples
// (https://github.com/TanStack/table/blob/main/examples/react/filters/src/main.tsx).
interface IDebouncedInputHook<T = string | number>
  extends Omit<HtmlHTMLAttributes<HTMLInputElement>, "value"> {
  value?: T;
  debounceTime?: number;
  setValueFunc?: Dispatch<SetStateAction<T>>;
  onChangeDirect?: (arg0: T) => void;
}
const useDebouncedInput = ({
  value: initialValue = "",
  debounceTime = 750,
  setValueFunc = undefined,
  onChangeDirect = undefined,
}: IDebouncedInputHook) => {
  const [value, setValue] = useState(initialValue);

  // Track whether a timeout is in progress, either in the browser or in a
  // Node.js or Deno process. Thanks to https://stackoverflow.com/a/56239226.
  const [t, setT] = useState<null | ReturnType<typeof setTimeout>>(null);

  // Reset value state whenever `initialValue` changes
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // On field change, wait at least `debounceTime` milliseconds before calling
  // the user-provided set-state function.
  const onChange = (input_value: typeof value) => {
    if (t) clearTimeout(t);
    setValue(input_value);
    setT(
      setTimeout(() => {
        if (typeof setValueFunc !== "undefined") {
          try {
            setValueFunc(input_value);
          } catch (error) {
            console.log("Failed to update state with setValueFunc");
          }
        }
        if (typeof onChangeDirect !== "undefined") {
          try {
            onChangeDirect(input_value);
          } catch (error) {
            console.log("Failed to update state with onChangeDirect");
          }
        }
      }, debounceTime)
    );
  };

  return [value, onChange, setValue] as const;
};

export const MyTextInput = (
  props: { label: string } & FieldHookConfig<string> &
    // Need to include other attribute types; thanks to
    // https://stackoverflow.com/a/72689646/11536255
    InputHTMLAttributes<HTMLInputElement> &
    ClassAttributes<HTMLInputElement>
) => {
  const label = props.label;
  const [field, meta, helpers] = useField<string>(props);
  const { onChange, ...fieldProps } = field;

  // Use debounced field
  const [text, setTextDebounced, setTextDirect] = useDebouncedInput({
    debounceTime: 250,
    setValueFunc: helpers.setValue,
  });

  // Also ensure that debounced field caches are updated on form data update
  useEffect(() => {
    setTextDirect(field.value);
  }, [field.value]);

  return (
    <div>
      <label
        htmlFor={props.id || props.name}
        className="block pb-1 text-sm font-semibold text-gray-600 dark:text-gray-400"
      >
        {label}
      </label>
      <Field
        className={cn(
          "w-full px-3 py-2 mt-1 mb-3 text-sm rounded-lg focus:ring-blue-400 focus:ring-2 shadow-sm shadow-gray-300 dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition dark:bg-slate-500 outline-none",
          meta.touched && meta.error
            ? " ring-2 ring-red-800 dark:ring-red-300 border-red-800 dark:border-red-300"
            : ""
        )}
        {...field}
        {...props}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTextDebounced(e.target.value);
        }}
        value={text}
      />
      {/* <span>Text: {field.value}</span> */}
      {meta.touched && meta.error && (
        <div className="pb-3 text-sm font-semibold text-red-800 border-none dark:text-red-300">
          {meta.error}
        </div>
      )}
    </div>
  );
};

export const MyTextAreaInput = (
  props: { label: string; nrows?: number } & FieldHookConfig<string> &
    // Need to include other attribute types; thanks to
    // https://stackoverflow.com/a/72689646/11536255
    InputHTMLAttributes<HTMLInputElement> &
    ClassAttributes<HTMLInputElement>
) => {
  const label = props.label;
  const nrows = typeof props?.nrows === "undefined" ? 5 : props.nrows;
  const [field, meta, helpers] = useField<string>(props);

  // Use debounced field
  const [text, setTextDebounced, setTextDirect] = useDebouncedInput({
    debounceTime: 250,
    setValueFunc: helpers.setValue,
  });

  // Also ensure that debounced field caches are updated on form data update
  useEffect(() => {
    setTextDirect(field.value);
  }, [field.value]);

  return (
    <div className="w-full">
      <label
        htmlFor={props.id || props.name}
        className="block pb-1 text-sm font-semibold text-gray-600 dark:text-gray-400"
      >
        {label}
      </label>
      <Field
        as="textarea"
        rows={nrows}
        className={`w-full px-3 py-2 mt-1 mb-3 text-sm rounded-lg focus:ring-blue-400 focus:ring-2 shadow-sm shadow-gray-300 dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition dark:bg-slate-500 outline-none ${
          meta.touched && meta.error
            ? " ring-2 ring-red-800 dark:ring-red-300 border-red-800 dark:border-red-300"
            : ""
        }`}
        {...field}
        {...props}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setTextDebounced(e.target.value);
        }}
        value={text}
      />
      {meta.touched && meta.error && (
        <div className="pb-3 text-sm font-semibold text-red-800 border-none dark:text-red-300">
          {meta.error}
        </div>
      )}
    </div>
  );
};

type T1 = SelectHTMLAttributes<HTMLSelectElement> &
  ClassAttributes<HTMLSelectElement>;
interface IMySelectInput extends T1 {
  label: string;
  name: string;
  type: string;
  choices: { id: number | string; name: string }[];
}
export const MySelectInput: FC<IMySelectInput> = (props) => {
  const [field, meta] = useField<number | undefined>(props);

  const { id, name, label, choices, ...rest } = props;

  return (
    <div>
      <label
        htmlFor={id || name}
        className="block pb-1 text-sm font-semibold text-gray-600 dark:text-gray-400"
      >
        {label}
      </label>
      <Field
        as="select"
        className={`w-full px-3 py-2 mt-1 mb-3 text-sm rounded-lg focus:ring-blue-400 focus:ring-2 shadow-sm shadow-gray-300 dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition dark:bg-slate-500 outline-none ${
          meta.touched && meta.error
            ? " ring-2 ring-red-800 dark:ring-red-300 border-red-800 dark:border-red-300 border-none"
            : ""
        }`}
        {...field}
        {...rest}
      >
        <option value="null">Select option...</option>
        {choices.map((choice, idx) => (
          <option key={idx} value={choice.id}>
            {choice.name}
          </option>
        ))}
      </Field>
      {meta.touched && meta.error && (
        <div className="pb-3 text-sm font-semibold text-red-800 dark:text-red-300">
          {meta.error}
        </div>
      )}
    </div>
  );
};

type T2 = InputHTMLAttributes<HTMLInputElement> &
  ClassAttributes<HTMLInputElement>;

interface IMyCheckboxInput extends T2 {
  label: string;
  name: string;
}

export const MyCheckboxInputTopLabel: FC<IMyCheckboxInput> = (props) => {
  const [field, meta] = useField<boolean | undefined>(props);

  const { id, name, label } = props;

  return (
    <div>
      <label
        htmlFor={id || name}
        className="block pb-1 text-sm font-semibold text-gray-600 dark:text-gray-400"
      >
        {label}
      </label>
      <div className="flex items-center justify-center">
        <Field
          type="checkbox"
          className={`h-6 w-6 mt-2 text-sm rounded-lg focus:ring-blue-400 focus:ring-2 shadow-sm shadow-gray-300 dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition dark:bg-slate-500 outline-none ${
            meta.touched && meta.error
              ? " ring-2 ring-red-800 dark:ring-red-300 border-red-800 dark:border-red-300 border-none"
              : ""
          }`}
          {...field}
        />
      </div>
      {meta.touched && meta.error && (
        <div className="pb-3 text-sm font-semibold text-red-800 dark:text-red-300"></div>
      )}
    </div>
  );
};

export const MyCheckboxInputSideLabel: FC<IMyCheckboxInput> = (props) => {
  const [field, meta] = useField<boolean | undefined>(props);

  const { id, name, label } = props;

  return (
    <div className="flex flex-row items-center gap-x-2">
      <div className="flex items-center justify-center">
        <Field
          type="checkbox"
          className={`h-6 w-6 text-sm rounded-lg focus:ring-blue-400 focus:ring-2 shadow-sm shadow-gray-300 dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition dark:bg-slate-500 outline-none ${
            meta.touched && meta.error
              ? " ring-2 ring-red-800 dark:ring-red-300 border-red-800 dark:border-red-300 border-none"
              : ""
          }`}
          {...field}
        />
      </div>
      <label
        htmlFor={id || name}
        className="block text-sm font-semibold text-gray-600 dark:text-gray-400"
      >
        {label}
      </label>
      {meta.touched && meta.error && (
        <div className="pb-3 text-sm font-semibold text-red-800 dark:text-red-300"></div>
      )}
    </div>
  );
};

type T3 = InputHTMLAttributes<HTMLInputElement> &
  ClassAttributes<HTMLInputElement>;
type TReactSpreadsheetValues = Matrix<{ value: number | string }>;

interface IMyReactSpreadsheetInput {
  name: string;
  label: string;
}

export const MyDataSeriesValuesField: FC<IMyReactSpreadsheetInput> = (
  props
) => {
  // Want a wrapper component to edit the xdata and ydata arrays of a scatter
  // plot data series at the same time. Need to access and update both columns
  // in the same component.
  // const [field, meta, helpers] = useField<TReactSpreadsheetValues | undefined>(
  //   props
  // );
  const { values, setValues, getFieldMeta } =
    useFormikContext<ISingleDataSeries>();
  const { touched: x_touched, error: x_error } = getFieldMeta("xdata");
  const { touched: y_touched, error: y_error } = getFieldMeta("ydata");
  const { name, label } = props;

  // Need to be able to zip and unzip data in order to get it inot and out of
  // the spreadsheet component.
  const arrValues = xyArraysToMatrixValues(values.xdata, values.ydata);

  return (
    <div>
      <span className="block pb-1 text-sm font-semibold text-gray-600 dark:text-gray-400">
        {label}
      </span>
      <div className="flex items-center justify-center">
        <Spreadsheet data={arrValues} />
      </div>
      {(x_touched && x_error) ||
        (y_touched && y_error && (
          <div className="pb-3 text-sm font-semibold text-red-800 dark:text-red-300">
            {x_error} {y_error}
          </div>
        ))}
    </div>
  );
};

// Zip xdata and ydata into array for ReactGrid to display.
const zipToReactGridRows: ({
  xdata,
  ydata,
}: {
  xdata: number[];
  ydata: number[];
}) => IDataPoint[] = ({ xdata, ydata }) => {
  // Just take the easy way and cut off after the shorter of the two arrays.
  var minlength = Math.min(xdata.length, ydata.length);
  var result = [];

  for (let i = 0; i < minlength; i++) {
    result.push({ x: xdata[i], y: ydata[i] });
  }

  return result;
};

// Also have reverse function to separate ReactGrid data into separate arrays.
const unzipReactGridRows: (data: IDataPoint[]) => {
  xdata: number[];
  ydata: number[];
} = (data) => {
  var xdata = data.map((elem, _) => elem.x);
  var ydata = data.map((elem, _) => elem.y);

  return { xdata, ydata };
};

// Formik field wrapper for ReactGrid -- operating on both xdata and ydata
// fields in the single data series model.
export interface IMyReactGridInput {
  label: string;
  name: string;
  idx: number;
}

export const MyReactGridInput: FC<IMyReactGridInput> = (props) => {
  const { values, setValues, getFieldMeta } =
    useFormikContext<IScatterPlotWithDataSeries>();
  // const { touched: x_touched, error: x_error } = getFieldMeta("xdata");
  // const { touched: y_touched, error: y_error } = getFieldMeta("ydata");
  const { name, label, idx } = props;

  const { resetKey } = useContext(PlotEditorFormExtraContext);

  const series = values.data_series[idx];
  const xdata = series.xdata;
  const ydata = series.ydata;

  // Initialize grid data along with setter to pass to ReactGrid wrapper.
  const [data, setData] = useState<IDataPoint[]>(() =>
    zipToReactGridRows({ xdata, ydata })
  );

  // Change data when we move to a different data series
  useEffect(
    useCallback(() => {
      // When index changes, reset data state to correct xdata and ydata
      setData(zipToReactGridRows({ xdata, ydata }));
    }, [idx, resetKey]),
    [idx, resetKey]
  );

  // When data changes, want to persist current data to the form state.
  useEffect(
    useCallback(() => {
      let newSeries = { ...series };
      const { xdata: newXdata, ydata: newYdata } = unzipReactGridRows(data);

      newSeries.xdata = newXdata;
      newSeries.ydata = newYdata;

      // In data series array, replace old version of our series with the new
      // one.
      let newDataSeriesArr = [...values.data_series];
      newDataSeriesArr[idx] = newSeries;

      setValues((values) => ({
        ...values,
        data_series: newDataSeriesArr,
      }));
    }, [data]),
    [data, setData]
  );

  const MemoizedFeaturedReactGrid = useMemo(() => FeaturedReactGrid, []);

  return (
    <div>
      <span className="block pb-1 text-sm font-semibold text-gray-600">
        {label}
      </span>
      {xdata && ydata ? (
        <MemoizedFeaturedReactGrid data={data} setData={setData} />
      ) : (
        <div className="italic text-slate-600 dark:text-slate-300">
          No data to show.
        </div>
      )}
    </div>
  );
};

// Have custom error field for inner records of field arrays
export const FieldArrayInnerErrorMessage = ({
  name,
  debug = false,
}: {
  name: string;
  debug?: boolean;
}) => {
  const [field, meta] = useField(name);

  console.log(name);

  return debug ? (
    <>
      <div>Field value: {field.value}</div>
      <div className="text-sm font-semibold text-red-700 dark:text-red-300">
        General errors: {meta.touched && meta.error ? meta.error : "No errors"}
      </div>
      <div className="text-sm font-semibold text-red-700 dark:text-red-300">
        Touched: {meta.touched ? "Yes" : "No"}
      </div>
      <div className="text-sm font-semibold text-red-700 dark:text-red-300">
        Error: {meta.error ? meta.error : "No errors"}
      </div>
    </>
  ) : meta.error && meta.touched ? (
    <div className="text-sm font-semibold text-red-700 dark:text-red-300">
      {meta.error}
    </div>
  ) : null;
};

export const FieldArrayOuterErrorMessage: FC<{
  name: string;
  debug?: boolean;
}> = ({ name, debug = false }) => {
  // Assume that the errors dict will have only strings as keys and values.
  const { errors } = useFormikContext<{ [x: string]: string }>();

  return debug ? (
    <div className="text-sm font-semibold text-red-700 dark:text-red-300">
      {typeof errors[name] === "string" ? errors[name] : "No errors"}
    </div>
  ) : typeof errors[name] === "string" ? (
    <div className="text-sm font-semibold text-red-700 dark:text-red-300">
      {errors[name]}
    </div>
  ) : null;
};

// Also want to have some simpler fields uncontrolled by Formik
interface IUnboundMyTextInput<T = string | number> {
  value: T;
  updateHandler?: Dispatch<SetStateAction<T>>;
  onChangeDirect?: (arg0: T | ChangeEvent<any>) => void;
  className?: string;
  placeHolder?: T;
  showClearButton?: boolean;
}
export const UnboundMyTextInput: FC<
  IUnboundMyTextInput &
    Omit<
      InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement>,
      "value" | "className" | "placeHolder"
    >
> = ({
  value: initialValue,
  updateHandler = undefined,
  onChangeDirect = undefined,
  className = "",
  placeHolder = "",
  showClearButton = true,
  ...rest
}) => {
  // Use debounced field
  const [text, setTextDebounced, setTextDirect] = useDebouncedInput({
    value: initialValue,
    debounceTime: 250,
    setValueFunc: updateHandler,
    onChangeDirect,
  });

  // Also ensure that debounced field caches are updated on form data update
  useEffect(() => {
    setTextDebounced(initialValue);
  }, [initialValue]);

  return (
    <div className="flex items-center">
      <input
        className={cn(
          "w-full px-3 py-2 text-sm rounded-lg focus:ring-blue-400 focus:ring-2 shadow-sm shadow-gray-300 dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition dark:bg-slate-500 dark:text-slate-100 dark:placeholder:text-slate-200 outline-none placeholder:italic",
          showClearButton ? "pr-5" : "input-number-no-spinner",
          className
        )}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTextDebounced(e.target.value);
        }}
        value={text}
        placeholder={placeHolder ? String(placeHolder) : ""}
        {...rest}
      />
      {showClearButton ? (
        <button
          role="button"
          className="-ml-7"
          onClick={() => setTextDebounced("")}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="w-4 h-4 text-slate-500 dark:text-slate-200"
          />
        </button>
      ) : null}
    </div>
  );
};

type T4 = InputHTMLAttributes<HTMLInputElement> &
  ClassAttributes<HTMLInputElement>;
interface IMySingleFileInputField extends T4 {
  label: string;
  name: string;
  fileRef: Ref<HTMLInputElement>;
}
export const MySingleFileInputField: FC<IMySingleFileInputField> = (props) => {
  const [field, meta] = useField<string>(props);
  const { id, label, name, fileRef } = props;

  return (
    <div>
      <label
        htmlFor={id || name}
        className="block pb-1 text-sm font-semibold text-gray-600 dark:text-gray-400"
      >
        {label}
      </label>
      <input
        {...field}
        className={cn(
          "w-full px-3 py-2 mt-1 mb-3 text-sm rounded-lg focus:ring-blue-400 focus:ring-2 shadow-sm shadow-gray-300 dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition dark:bg-slate-500 outline-none",
          meta.touched && meta.error
            ? " ring-2 ring-red-800 dark:ring-red-300 border-red-800 dark:border-red-300"
            : ""
        )}
        type="file"
        multiple={false}
        ref={fileRef}
      />
      {meta.touched && meta.error && (
        <div className="pb-3 text-sm font-semibold text-red-800 border-none dark:text-red-300">
          {meta.error}
        </div>
      )}
    </div>
  );
};
