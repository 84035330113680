// Custom wrappers to make a spreadsheet component for inputting data.

import {
  ReactGrid,
  type Column,
  type Row,
  type CellChange,
  type NumberCell,
  type MenuOption,
  type SelectionMode,
  type Id,
} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { FC, useCallback, useMemo, useState } from "react";
import {
  getCtrlKeySymbol,
  isMacOs,
  pluralizeByLength,
} from "../../lib/text-utils";
import { KeyboardEvent as ReactKeyboardEvent } from "react";
import {
  ArrowDownIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { ButtonToolbarContainer, GenericButton } from "../buttons";
import { BarsArrowUpIcon } from "@heroicons/react/24/outline";

// Can define some rudimentary interfaces
export interface IDataPoint {
  x: number;
  y: number;
}

export const randomData: IDataPoint[] = Array(1500)
  .fill(0)
  .map((elem, idx) => ({ x: Math.random(), y: Math.random() }));

// Utility to remove zero or null padding from start and end of 2D arrays used
// with @silevis/reactgrid. This operates on rows (i.e., trim all rows that are
// filled with zeros, nulls, etc.). Based on
// https://www.geeksforgeeks.org/remove-leading-zeros-from-an-array/#.
const removePadding2D: (args: {
  initialArray: IDataPoint[];
  how: "any" | "all";
}) => IDataPoint[] = ({ initialArray, how }) => {
  // Need axis and "how" params, like pandas.
  var arr = initialArray;

  var nr = arr.length;
  var idxStart = 0;
  var idxEnd = nr;

  // Test whether an element is null/undefined/zero/etc.
  const isBlank = (element: any) =>
    isNaN(element) ||
    element === null ||
    typeof element === "undefined" ||
    (typeof element === "number" && element === 0) ||
    (typeof element === "string" && element === "");

  // Scan array from start to find first non-null row
  for (var i = 0; i < nr; i++) {
    // Start from beginning, discarding row only if all of its values are
    // null/undefined/zero, etc.
    let values = [arr[i].x, arr[i].y];
    if (!values[how === "any" ? "some" : "every"](isBlank)) {
      idxStart = i;
      break;
    }
  }

  // Scan array from end to find last non-null column
  for (var i = nr - 1; i >= 0; i--) {
    let values = [arr[i].x, arr[i].y];
    if (!values[how === "any" ? "some" : "every"](isBlank)) {
      idxEnd = i;
      break;
    }
  }

  // If entire array ends up being blank, then just return single row of zeros.
  if (idxStart === 0 && idxEnd === nr) {
    return [{ x: 0, y: 0 }];
  }

  // Now reconstruct array with only the valid rows
  var finalArray = [...arr.slice(idxStart, idxEnd + 1)];

  return finalArray;
};

export const FeaturedReactGrid: FC<{
  data: IDataPoint[];
  setData: React.Dispatch<React.SetStateAction<IDataPoint[]>>;
}> = ({ data, setData }) => {
  const initialColumns: Column[] = useMemo(
    () => [
      { columnId: "x", width: 150, resizable: true },
      { columnId: "y", width: 150, resizable: true },
    ],
    []
  );
  const [columns, setColumns] = useState<Column[]>(initialColumns);

  const headerRow: Row = useMemo(
    () => ({
      rowId: "header",
      cells: [
        { type: "header", text: "X Values" },
        { type: "header", text: "Y Values" },
      ],
    }),
    []
  );

  const getRows = useCallback(
    (data: IDataPoint[]): Row[] => [
      headerRow,
      ...data.map<Row>((elem, idx) => ({
        rowId: idx,
        cells: [
          { type: "number", value: elem.x },
          { type: "number", value: elem.y },
        ],
      })),
    ],
    [headerRow, data]
  );

  const rows = getRows(data);

  const handleColumnResize = (col_id: Id, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex(
        (elem) => elem.columnId === col_id
      );
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  // Selection menu handler
  const handleContextMenu = useCallback(
    (
      selectedRowIds: Id[],
      selectedColIds: Id[],
      selectionMode: SelectionMode,
      menuOptions: MenuOption[]
    ): MenuOption[] => {
      if (["range", "row"].some((value) => selectionMode.includes(value))) {
        menuOptions = [
          ...menuOptions,
          {
            id: "removePerson",
            label: "Delete row",
            handler: () => {
              setData((prevRows) => {
                return [
                  ...prevRows.filter((_, idx) => !selectedRowIds.includes(idx)),
                ];
              });
            },
          },
          {
            id: "addRowsAtBottom",
            label: "Add 1000 blank rows at bottom",
            handler: handleAddRowsAtBottom,
          },
        ];
      }
      return menuOptions;
    },
    []
  );

  // Undo/Redo handlers
  const [cellChangesIndex, setCellChangesIndex] = useState(() => -1);
  const [cellChanges, setCellChanges] = useState<CellChange<NumberCell>[][]>(
    () => []
  );
  const applyNewValue = useCallback(
    (
      changes: CellChange<NumberCell>[],
      prevData: IDataPoint[],
      usePrevValue: boolean = false
    ): IDataPoint[] => {
      changes.forEach((change) => {
        const rowId = change.rowId;
        const fieldName = change.columnId;
        const cell = usePrevValue ? change.previousCell : change.newCell;
        prevData[rowId][fieldName] = cell.value;
      });
      return [...prevData];
    },
    []
  );

  const applyChangesToCells = useCallback(
    (
      changes: CellChange<NumberCell>[],
      prevData: IDataPoint[]
    ): IDataPoint[] => {
      const updated = applyNewValue(changes, prevData);
      setCellChanges((prevChanges) => [
        ...prevChanges.slice(0, cellChangesIndex + 1),
        changes,
      ]);
      setCellChangesIndex(cellChangesIndex + 1);
      return updated;
    },
    [cellChangesIndex]
  );

  const handleChanges = (changes: CellChange<NumberCell>[]) => {
    setData((prevState) => applyChangesToCells(changes, prevState));
  };

  const undoChanges = useCallback(
    (
      changes: CellChange<NumberCell>[],
      prevData: IDataPoint[]
    ): IDataPoint[] => {
      const updated = applyNewValue(changes, prevData, true);
      setCellChangesIndex(cellChangesIndex - 1);
      return updated;
    },
    [cellChangesIndex]
  );

  const redoChanges = useCallback(
    (
      changes: CellChange<NumberCell>[],
      prevData: IDataPoint[]
    ): IDataPoint[] => {
      const updated = applyNewValue(changes, prevData, false);
      setCellChangesIndex(cellChangesIndex + 1);
      return updated;
    },
    [cellChangesIndex]
  );

  const clearData = useCallback(() => {
    setData([
      ...Array(5)
        .fill(0)
        .map(() => ({ x: 0, y: 0 })),
    ]);
    setCellChanges([]);
    setCellChangesIndex(-1);
  }, []);

  const handleUndoChanges = useCallback(() => {
    if (cellChangesIndex >= 0) {
      setData((prevData) =>
        undoChanges(cellChanges[cellChangesIndex], prevData)
      );
    }
  }, [cellChanges, cellChangesIndex]);

  const handleRedoChanges = useCallback(() => {
    if (cellChangesIndex + 1 <= cellChanges.length - 1) {
      setData((prevData) =>
        redoChanges(cellChanges[cellChangesIndex + 1], prevData)
      );
    }
  }, [cellChanges, cellChangesIndex]);

  const handleAddRowsAtBottom = useCallback(() => {
    // Add like 1000 more rows at the bottom
    setData((prevData) => [
      ...prevData,
      ...Array(1000)
        .fill(0)
        .map(() => ({ x: 0, y: 0 })),
    ]);
    setCellChanges([]);
    setCellChangesIndex(-1);
  }, []);

  const handleClearData = useCallback(() => {
    return clearData();
  }, []);

  const handleTrimData = useCallback(() => {
    setData((prevData) =>
      removePadding2D({ initialArray: prevData, how: "all" })
    );
    setCellChanges([]);
    setCellChangesIndex(-1);
  }, [cellChanges, cellChangesIndex]);

  const handleUndoRedoKeyboardEvents = useCallback(
    (e: ReactKeyboardEvent<HTMLDivElement>) => {
      if ((!isMacOs() && e.ctrlKey) || e.metaKey) {
        switch (e.key) {
          case "z":
            handleUndoChanges();
            return;
          case "y":
            handleRedoChanges();
            return;
        }
      }
    },
    [cellChanges, cellChangesIndex]
  );

  return (
    // Have wrapper div to handle keyboard shortcuts
    <div onKeyDown={handleUndoRedoKeyboardEvents}>
      <div className="mt-2 italic text-slate-500 dark:text-slate-300">
        Showing {data.length} row{pluralizeByLength(data)}.
      </div>
      <ButtonToolbarContainer>
        <GenericButton
          onClick={handleUndoChanges}
          icon={<ArrowUturnLeftIcon className="w-4 h-4" />}
          tooltip_text={`${getCtrlKeySymbol()} + z`}
        >
          Undo
        </GenericButton>
        <GenericButton
          onClick={handleRedoChanges}
          icon={<ArrowUturnRightIcon className="w-4 h-4" />}
          tooltip_text={`${getCtrlKeySymbol()} + y`}
        >
          Redo
        </GenericButton>
        <GenericButton
          onClick={handleClearData}
          icon={<TrashIcon className="w-4 h-4" />}
          tooltip_text="Clears all data from spreadsheet and undo/redo history."
        >
          Clear
        </GenericButton>
        <GenericButton
          onClick={handleAddRowsAtBottom}
          icon={<ArrowDownIcon className="w-4 h-4" />}
          tooltip_text="Adds 1000 rows of zeros to bottom."
        >
          Add rows
        </GenericButton>
        <GenericButton
          onClick={handleTrimData}
          icon={<BarsArrowUpIcon className="w-4 h-4" />}
          tooltip_text="Removes blank/zero rows at top and bottom of data."
        >
          Trim
        </GenericButton>
      </ButtonToolbarContainer>
      <div className="h-64 overflow-y-scroll">
        <ReactGrid
          rows={rows}
          columns={columns}
          onCellsChanged={handleChanges}
          onColumnResized={handleColumnResize}
          onContextMenu={handleContextMenu}
          //   enableRowSelection
          enableColumnSelection
          enableRangeSelection
          stickyTopRows={1}
        />
      </div>
    </div>
  );
};
