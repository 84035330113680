// Button components
import React, { ReactElement, forwardRef } from "react";
import { cn } from "../lib/text-utils";
import { LoadingSpinnerBtn } from "./spinners";

export interface IGenericButton {
  accentedButton?: boolean;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  disabled?: React.ButtonHTMLAttributes<HTMLButtonElement>["disabled"];
  icon?: JSX.Element;
  tooltip_text?: string;
  tooltip_text_disabled?: string;
  className?: string;
}

export const GenericButton = forwardRef<
  HTMLButtonElement,
  IGenericButton &
    Omit<
      React.HtmlHTMLAttributes<HTMLButtonElement>,
      "onClick" | "disabled" | "className"
    >
>(function GenericButton(
  {
    children,
    accentedButton = false,
    onClick,
    type,
    icon,
    tooltip_text = "",
    tooltip_text_disabled = "",
    className = "",
    ...rest
  },
  ref
) {
  let colorChoices;
  if (accentedButton) {
    colorChoices = {
      text: "text-white",
      background: "bg-slate-600",
      hoverBackground: "group-hover:bg-slate-800",
      darkBackground: "dark:bg-slate-500",
      darkHoverBackground: "dark:group-hover:bg-slate-400",
      focus: "focus:ring-slate-300",
      darkFocus: "dark:focus:ring-slate-800",
    };
  } else {
    colorChoices = {
      text: "text-normal",
      background: "bg-slate-200",
      hoverBackground: "group-hover:bg-slate-300",
      darkBackground: "dark:bg-slate-600",
      darkHoverBackground: "dark:group-hover:bg-slate-700",
      focus: "focus:ring-slate-300",
      darkFocus: "dark:focus:ring-slate-800",
    };
  }

  // Make tooltips wider for lots of text.
  var toolTipDimClasses =
    tooltip_text && tooltip_text.length > 10 ? "-left-8 -right-8" : "";

  var disabledClasses = rest.disabled
    ? "cursor-not-allowed italic text-slate-400 dark:text-slate-500"
    : "";

  return (
    <div className="relative group">
      <button
        type={type ? type : "button"}
        onClick={(e) => {
          if (typeof onClick === "function") {
            // Need to pass event object e to make react-menu stuff work.
            onClick(e);
          }
        }}
        className={cn(
          "peer",
          colorChoices.text,
          colorChoices.background,
          colorChoices.hoverBackground,
          "focus:ring-4 focus:outline-none",
          colorChoices.focus,
          "font-medium rounded-lg text-sm",
          icon ? "px-3" : "px-5",
          "py-2.5 text-center",
          colorChoices.darkBackground,
          colorChoices.darkHoverBackground,
          colorChoices.darkFocus,
          "h-10 flex place-items-center gap-x-2 w-full justify-center",
          disabledClasses,
          className
        )}
        {...rest}
        ref={ref}
      >
        {icon}
        <div>{children}</div>
      </button>
      {((tooltip_text && tooltip_text.length > 0 && !rest.disabled) ||
        (tooltip_text_disabled &&
          tooltip_text_disabled.length > 0 &&
          rest.disabled)) && (
        <>
          <div
            role="tooltip"
            //
            className={cn(
              "absolute hidden peer-hover:inline-block z-10 bottom-14 bg-gray-700 dark:bg-gray-400 font-medium shadow-sm shadow-gray-500 dark:shadow-gray-600 text-white dark:text-slate-800 py-2 px-3 text-sm text-center rounded-lg inset-x-0 mx-0 my-0",
              toolTipDimClasses
            )}
          >
            {rest.disabled && typeof tooltip_text_disabled === "string"
              ? tooltip_text_disabled
              : tooltip_text}
          </div>
          <div className="absolute z-10 hidden w-0 h-0 translate-x-1/2 border-t-8 border-b-8 border-l-8 border-r-8 peer-hover:block -top-4 border-l-transparent border-r-transparent border-b-transparent border-t-gray-700 dark:border-t-gray-400 right-1/2"></div>
        </>
      )}
    </div>
  );
});

interface ISubmitButton extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  isSubmitting: boolean;
  accentedButton?: boolean;
}

export const SubmitButton: React.FC<ISubmitButton> = ({
  isSubmitting,
  accentedButton = true,
  ...props
}) => {
  // Pass to GenericButton everything but the click handler
  const { onClick, ...rest } = props;

  return (
    <GenericButton accentedButton={accentedButton} type="submit" {...rest}>
      {isSubmitting ? (
        <div className="flex items-center gap-x-2">
          <LoadingSpinnerBtn addtlClasses="w-4 h-4 text-gray-200 dark:text-gray-400 fill-blue-600" />{" "}
          Saving...
        </div>
      ) : (
        <div>{rest.children ? rest.children : "Save"}</div>
      )}
    </GenericButton>
  );
};

// Have a general button toolbar container. I'm lazy and don't feel like
// constraining the children to be only buttons, so let's just agree that we'll
// only put buttons inside this container.
interface IButtonToolbarContainer {
  children?: ReactElement<any, any> | ReactElement<any, any>[];
  className?: string;
}

export const ButtonToolbarContainer: React.FC<IButtonToolbarContainer> = ({
  children,
  className = "",
}) => (
  <div
    className={cn(
      "flex flex-wrap w-full px-2 py-2 bg-white rounded-t-lg shadow-sm dark:bg-slate-600 gap-x-2 gap-y-2",
      className
    )}
  >
    {children}
  </div>
);
