// Top-level Formik form component for the scatter plot editor

import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  lazy,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { GenericButton, SubmitButton } from "../../../../components/buttons";
import {
  FieldArrayOuterErrorMessage,
  MyCheckboxInputSideLabel,
  MySelectInput,
  MyTextAreaInput,
  MyTextInput,
} from "../../../../components/formik-form-components";
import {
  MascApiFetchErrorsTypeset,
  SimpleToastControlled,
} from "../../../../components/message-indicators";
import { TabbedContent } from "../../../../components/tabbed-content";
import { TFetchGeneralError } from "../../../../lib/fetcher";
import { validationSchemaSingleDataSeries } from "./scatter-plots-form-dataseries";
const DataSeriesEditor = lazy(() => import("./scatter-plots-form-dataseries"));

// Use granular context for extra form state (i.e., to force ReactGrid wrapper
// to re-render on form reset).
interface IPlotEditorFormExtraContext {
  resetKey: number;
  setResetKey: Dispatch<SetStateAction<number>>;
}
export const PlotEditorFormExtraContext =
  createContext<IPlotEditorFormExtraContext>({} as IPlotEditorFormExtraContext);

// Also want a context for the field array in the data series editor
interface IFieldArrayExtraContext {
  fieldArrayHelpers: FieldArrayRenderProps;
}
export const FieldArrayExtraContext = createContext<IFieldArrayExtraContext>(
  {} as IFieldArrayExtraContext
);

// Validation for the form
export const validationSchemaPlotData = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .min(3, "Must be at least 3 characters"),
  notes: Yup.string().optional(),
  x_axis_name: Yup.string()
    .required("Must have a name for this axis")
    .min(2, "Must be at least 2 characters"),
  x_axis_scale: Yup.string()
    .required("Required")
    .oneOf(["lin", "log"], "Must be either linear or logarithmic"),
  y_axis_name: Yup.string()
    .required("Must have a name for this axis")
    .min(2, "Must be at least 2 characters"),
  y_axis_scale: Yup.string()
    .required("Required")
    .oneOf(["lin", "log"], "Must be either linear or logarithmic"),
  y_axis_2_name: Yup.string()
    .min(2, "Must be at least 2 characters")
    .default("Untitled Axis"),
  y_axis_2_scale: Yup.string()
    .oneOf(["lin", "log"], "Must be either linear or logarithmic")
    .default("lin"),
  data_series: Yup.array().of(validationSchemaSingleDataSeries),
  // data_series_order: Yup.array().of(Yup.number()),
});

export const PlotEditorForm: FC<{
  initialValues: Omit<
    IScatterPlotWithDataSeries,
    "id" | keyof ICreatedModifiedAtByFields
  >;
  updatePlotData: (
    values: IScatterPlotWithDataSeries,
    formikHelpers: FormikHelpers<IScatterPlotWithDataSeries>
  ) => void | Promise<void>;
  charId: number;
  plotId?: number;
}> = ({ initialValues, updatePlotData, charId, plotId }) => {
  // Form reset key to force ReactGrid wrapper to rerender.
  const [resetKey, setResetKey] = useState(1);

  // History navigator so that Cancel button goes back one page
  const navigate = useNavigate();

  // Have an error message toast component just for Formik form errors.
  const [showToast, setShowToast] = useState(false);

  return (
    <PlotEditorFormExtraContext.Provider value={{ resetKey, setResetKey }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaPlotData}
        onSubmit={updatePlotData}
        onReset={(values) => {
          values = initialValues;
          setResetKey((prev) => prev + 1);
        }}
      >
        {({ isSubmitting, ...props }) => {
          let err: TFetchGeneralError<
            Omit<Partial<IScatterPlotWithDataSeries>, "id">
          >;
          err = new Error();
          if (props.errors && Object.keys(props.errors).length > 0) {
            err.message = "Errors in form";
            err.info = Object.fromEntries(
              Object.entries(props.errors).map(([k, v], _) => [k, [v]])
            );
          }

          // Calculate whether to show this form-level error toast. Want to
          // mirror the "error present and field has been touched" condition
          // for individual fields.
          const showError = !!(
            props.errors.name ||
            props.errors.x_axis_name ||
            props.errors.y_axis_name
          );

          return (
            <Form {...props}>
              {/* Toast for error notifications */}
              <div className="flex justify-center">
                <SimpleToastControlled
                  icon={<FontAwesomeIcon icon={faXmarkCircle} />}
                  message={<MascApiFetchErrorsTypeset error={err} />}
                  isVisible={showError}
                  setIsVisible={setShowToast}
                  iconStroke="text-orange-500"
                  className="mt-4"
                  iconBg="bg-orange-100"
                  showCloseBtn={false}
                />
              </div>
              <TabbedContent
                content={[
                  {
                    idx: 0,
                    displayName: "Basic Information",
                    element: (
                      <>
                        <div className="mt-1 text-sm italic text-slate-600">
                          Give this plot a title. Optionally write a short name
                          and some notes.
                        </div>
                        <div className="flex flex-wrap mt-3 gap-x-3">
                          <MyTextInput
                            label="Plot Name"
                            name="name"
                            type="text"
                            placeholder="Plot name"
                          />
                        </div>
                        <div className="flex flex-wrap mt-3">
                          <MyTextAreaInput
                            label="Notes"
                            name="notes"
                            type="text"
                            placeholder="Write notes here."
                          />
                        </div>
                      </>
                    ),
                  },
                  {
                    idx: 1,
                    displayName: "X Axis",
                    element: (
                      <>
                        <div className="mt-1 text-sm italic text-slate-600">
                          Choose options for the horizontal axis. Be aware that
                          using a logarithmic scale when there are negative
                          values in your data may cause that data series to
                          disappear.
                        </div>
                        <div className="flex flex-wrap mt-3 gap-x-3">
                          <MyTextInput
                            label="X Axis Name"
                            name="x_axis_name"
                            type="text"
                            placeholder="Label"
                          />
                          <MySelectInput
                            choices={[
                              { id: "lin", name: "Linear" },
                              { id: "log", name: "Logarithmic" },
                            ]}
                            label="Axis scale"
                            type="text"
                            name="x_axis_scale"
                          />
                        </div>
                      </>
                    ),
                  },
                  {
                    idx: 2,
                    displayName: "Y Axis",
                    element: (
                      <>
                        <div className="mt-1 text-sm italic text-slate-600">
                          Choose options for the left vertical axis. Be aware
                          that using a logarithmic scale when there are negative
                          values in your data may cause that data series to
                          disappear.
                        </div>
                        <div className="flex flex-wrap mt-3 gap-x-3">
                          <MyTextInput
                            label="Y Axis Name"
                            name="y_axis_name"
                            type="text"
                            placeholder="Label"
                          />
                          <MySelectInput
                            choices={[
                              { id: "lin", name: "Linear" },
                              { id: "log", name: "Logarithmic" },
                            ]}
                            label="Axis scale"
                            type="text"
                            name="y_axis_scale"
                          />
                        </div>
                      </>
                    ),
                  },
                  {
                    idx: 3,
                    displayName: "Second Y Axis",
                    element: (
                      <>
                        <div className="mt-1 text-sm italic text-slate-600">
                          Choose options for the right vertical axis. Be aware
                          that using a logarithmic scale when there are negative
                          values in your data may cause that data series to
                          disappear.
                        </div>
                        <div className="mt-3">
                          <MyCheckboxInputSideLabel
                            label="2nd Y axis active?"
                            name="y_axis_2_active"
                            type="checkbox"
                          />
                        </div>
                        <div className="flex flex-wrap mt-3 gap-x-3">
                          <MyTextInput
                            label="2nd Y Axis Name"
                            name="y_axis_2_name"
                            type="text"
                            placeholder="Label"
                          />
                          <MySelectInput
                            choices={[
                              { id: "lin", name: "Linear" },
                              { id: "log", name: "Logarithmic" },
                            ]}
                            label="Axis scale"
                            type="text"
                            name="y_axis_2_scale"
                          />
                        </div>
                      </>
                    ),
                  },
                  {
                    idx: 4,
                    displayName: "Data Series",
                    element: (
                      <>
                        <div className="mt-1 text-sm italic text-slate-600">
                          Create or edit data series for this plot. Note that
                          any data series set to plot on the 2nd Y axis will
                          show only if the 2nd Y axis is marked as active.
                        </div>
                        <div className="mt-3">
                          <FieldArray name="data_series">
                            {(fieldArrayHelpers) => (
                              <FieldArrayExtraContext.Provider
                                value={{ fieldArrayHelpers }}
                              >
                                <DataSeriesEditor
                                  data_series={props.values.data_series}
                                />
                              </FieldArrayExtraContext.Provider>
                            )}
                          </FieldArray>
                          <FieldArrayOuterErrorMessage name="data_series" />
                        </div>
                      </>
                    ),
                  },
                ]}
              />
              <div className="flex gap-x-2">
                <SubmitButton isSubmitting={isSubmitting} />
                <GenericButton accentedButton={false} type="reset">
                  Revert to saved
                </GenericButton>
                <GenericButton
                  accentedButton={false}
                  onClick={() =>
                    navigate(
                      plotId
                        ? `../characterizations/${charId}/plots/${plotId}`
                        : `../characterizations/${charId}/plots/`
                    )
                  }
                >
                  Cancel
                </GenericButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </PlotEditorFormExtraContext.Provider>
  );
};
